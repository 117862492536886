import React, { useEffect, useState } from "react";
import api from "../../../api";
import { toast } from "react-hot-toast";
import { containsOnlySpaces } from "../../../Components/Helpers/helper";
import {
    Autocomplete,
    DialogContent,
    DialogTitle,
    Modal,
    ModalDialog,
    Textarea,
} from "@mui/joy";
import Stack from "@mui/joy/Stack";
import Input from "@mui/joy/Input";
import FormLabel from "@mui/joy/FormLabel";
import Button from "@mui/joy/Button";
import { FaRegEdit, FaTasks } from "react-icons/fa";
import Divider from "@mui/joy/Divider";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import Typography from "@mui/joy/Typography";

const AddSecretForm = ({
    show,
    close,
    organisationId,
    onClose,
    existingSecret,
    projects = [],
    organisationMembers = [],
    currentUserId,
}) => {
    const [email, setEmail] = useState("");
    const [secret, setSecret] = useState("");
    const [tags, setTags] = useState("");
    const [description, setDescription] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const userEmail = useSelector((state) => state.auth.userName);

    useEffect(() => {
        if (existingSecret) {
            setIsEdit(false);
            setEmail(existingSecret?.email || "");
            setDescription(existingSecret?.description || "");
            setTags(existingSecret?.tags || "");
            setSecret(existingSecret?.asdjas || "");
            setSelectedProject(
                {
                    value: existingSecret?.project?._id,
                    label: existingSecret?.project?.name,
                } || null,
            );
            setSelectedUsers(
                existingSecret?.usersAccess?.map((user) => ({
                    value: user?._id,
                    label: user?.email,
                })) || [],
            );
        } else {
            setIsEdit(true);
            setEmail("");
            setDescription("");
            setTags("");
            setSecret("");
            setSelectedProject(null);
            setSelectedUsers([]);
            setButtonLoading(false);
        }
    }, [existingSecret, show]);

    const editSecret = async () => {
        if (!description || containsOnlySpaces(description)) {
            return toast.error("Description is mandatory");
        }

        const data = {
            description: description,
            tags: tags.toString(),
            password: secret,
            email: email,
            project: selectedProject?.value,
            users: selectedUsers?.map((user) => user?.value),
        };

        if (secret === "") {
            delete data.password;
        }

        setButtonLoading(true);
        const result = await api.updateSecret(existingSecret._id, data);
        setButtonLoading(false);

        if (result.status === 200) {
            toast.success("Secret Updated Successfully");
            close();
            onClose(result);
        } else {
            toast.error("Something went wrong, please try again later.");
        }
    };

    const createSecret = async () => {
        if (!description || containsOnlySpaces(description)) {
            return toast.error("Description is mandatory");
        }

        setButtonLoading(true);
        const data = {
            email: email,
            description: description,
            tags: tags,
            organisation: organisationId,
            password: secret,
            project: selectedProject?.value,
            users: selectedUsers?.map((user) => user.value),
        };

        const result = await api.createSecret(data);

        if (result.status === 201) {
            toast.success("Secret Added Successfully");
            close();
            onClose(result);
        } else {
            toast.error("Something went wrong, please try again later.");
        }

        setButtonLoading(false);
    };

    return (
        <Modal open={show} onClose={close}>
            <ModalDialog maxWidth="40%" minWidth="40%">
                <DialogTitle>
                    {existingSecret
                        ? isEdit
                            ? "Edit Credentials"
                            : " View Credentials"
                        : "Add Credentials"}
                </DialogTitle>
                <DialogContent
                    sx={{
                        d: "flex",
                        flexDirection: "row",
                        w: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="p" component="p">
                        Fill in the form below to add a new secret
                    </Typography>
                    {existingSecret?.createdBy?.email === userEmail &&
                        existingSecret && (
                            <Button
                                size="sm"
                                variant="outlined"
                                startDecorator={
                                    !isEdit ? (
                                        <FaRegEdit />
                                    ) : (
                                        <IoMdClose size={15} />
                                    )
                                }
                                onClick={() => setIsEdit(!isEdit)}
                            >
                                {!isEdit ? "Edit" : "Cancel Edit"}
                            </Button>
                        )}
                </DialogContent>
                <Divider />
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        existingSecret ? editSecret() : createSecret();
                    }}
                >
                    <Stack
                        sx={{
                            padding: 2,
                            gap: 2,
                            maxHeight: "70vh",
                            overflowY: "scroll",
                        }}
                    >
                        <FormLabel htmlFor="description">Description</FormLabel>
                        <Textarea
                            readOnly={!isEdit}
                            id="description"
                            placeholder="Example: This Password is for the company's AWS account (Mandatory) "
                            required
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            l
                        />
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Input
                            readOnly={!isEdit}
                            id="email"
                            type="text"
                            placeholder="Example: example@example.com (Optional)"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {isEdit && (
                            <FormLabel htmlFor="secret">Secret</FormLabel>
                        )}
                        {isEdit && (
                            <Input
                                id="secret"
                                type="text"
                                value={secret}
                                placeholder="(Optional)"
                                onChange={(e) => setSecret(e.target.value)}
                            />
                        )}
                        <FormLabel htmlFor="tags">Tags</FormLabel>
                        <Input
                            readOnly={!isEdit}
                            id="tags"
                            type="text"
                            value={tags}
                            placeholder="Example : AWS, GCP, Azure (Optional)"
                            onChange={(e) => setTags(e.target.value)}
                        />
                        <FormLabel htmlFor="project">Project</FormLabel>
                        <Autocomplete
                            readOnly={!isEdit}
                            options={projects?.map((project) => ({
                                value: project?._id,
                                label: project?.name,
                            }))}
                            startDecorator={<FaTasks />}
                            placeholder="Select Project (Optional)"
                            getOptionLabel={(option) => option?.label || ""}
                            isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                            }
                            value={selectedProject}
                            onChange={(event, newValue) => {
                                setSelectedProject(newValue);
                            }}
                            renderInput={(params) => <Input {...params} />}
                        />
                        <FormLabel htmlFor="users">Members</FormLabel>
                        <Autocomplete
                            readOnly={!isEdit}
                            multiple
                            id="users"
                            options={organisationMembers?.map((member) => ({
                                value: member?._id,
                                label: member?.email,
                                isDisabled: member._id === currentUserId,
                            }))}
                            getOptionLabel={(option) => option.label || ""}
                            isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                            }
                            value={selectedUsers}
                            placeholder="Select Members (Optional)"
                            onChange={(event, newValue) => {
                                setSelectedUsers(newValue);
                            }}
                            renderInput={(params) => <Input {...params} />}
                        />
                    </Stack>
                    <Divider />
                    {isEdit && (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                size="sm"
                                sx={{ my: 2 }}
                                type="submit"
                                color="primary"
                                loading={buttonLoading}
                            >
                                {existingSecret ? "Update Credentials" : "Save"}
                            </Button>
                        </div>
                    )}
                </form>
            </ModalDialog>
        </Modal>
    );
};

export default AddSecretForm;
