import React, { useEffect, useState } from "react";
import {
  BreadCrumbsHolder,
  ScreenWrapper,
} from "../../Components/UI/basic.style";
import {
  BodyContainer,
  Card,
  CardNameHolder,
  Heading,
  MainBody,
  Partition,
  PartitionBody,
  PartitionTitle,
} from "./project-view-style";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CreateProject from "./create-project/create-project";
import {
  isVideo,
  removeDomainNameFromEmail,
} from "../../Components/Helpers/helper";
import AddOrgMember from "./addOrgMember/add-Org-Member";
import { toast, Toaster } from "react-hot-toast";
import {
  CloseButton,
  Modal,
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
} from "../../Components/UI/modal.style";
import { IoIosCloseCircleOutline, IoMdAdd } from "react-icons/io";
import HoverMenu from "../../Components/Hover-Menu/hover-menu";
import AlertModal from "../../Components/Alert-modal/alert-modal";
import api from "../../api";
import { useSelector } from "react-redux";
import CreateOrg from "../Organisation/Create-org/create-org";
import CardSkeletonLoader from "../../Components/Loaders/Card-Skeleton-Loader";
import { CiViewTable } from "react-icons/ci";
import Ellipsis from "../../Components/Ellipsis/Ellipsis";
import UserInfoCard from "../../Components/user-info-card/user-info-card";
import { FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import Segment from "../../Components/segment/segment";
import SearchBar from "../../Components/Search-Bar/search-bar";
import { PiCardsLight, PiGear } from "react-icons/pi";
import { BiHome, BiLogoCreativeCommons } from "react-icons/bi";
import {
  AiOutlineAppstoreAdd,
  AiOutlineLock,
  AiOutlineUser,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { Box, Breadcrumbs, Button, Drawer, Table, Typography } from "@mui/joy";
import Divider from "@mui/joy/Divider";
import Link from "@mui/joy/Link";
import EditMemberRoleModal from "./editOrgMember/edit-org-member";

const ViewProjects = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalOrg, setModalOrg] = useState(false);
  const [organisationMember, setOrganisationMember] = useState([]);
  const [currentRole, setCurrentRole] = useState("");
  const [currentOrg, setCurrentOrg] = useState([{}]);
  const [memberData, setMemberData] = useState("");
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [removeMemberModal, setRemoveMemberModal] = useState(false);
  const [editMemberRoleModal, setEditMemberRoleModal] = useState(false);
  const [newRole, setNewRole] = useState(null);
  const addMemberToggle = () => setAddMemberModal(!addMemberModal);
  const toggle = () => setModal(!modal);
  const toggleOrg = () => setModalOrg(!modalOrg);
  const [projectDetails, setProjectDetails] = useState([{}]);
  const params = useParams();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const user = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [viewActive, setViewActive] = useState(true);
  const [viewChange, setViewChange] = useState(
    JSON.parse(localStorage.getItem("viewChange")) ?? true,
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [activeCard, setActiveCard] = useState(null);

  const [hideRightBar, setHideRightBar] = useState(false);

  const filteredData = projects?.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.trim().toLowerCase()),
    ),
  );

  function toggleTableView(val) {
    setViewChange(val);
    localStorage.setItem("viewChange", val);
  }

  const getCurrentUserRole = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}organisation/currentUser/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setCurrentRole(response?.data?.role);
      console.log(currentRole);
      setCurrentOrg(response.data.organisation);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const fetchProjects = async () => {
    const getPath =
      params.id !== ":id" ? `organisation/${params.id}` : "user-projects";
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}project/${getPath}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setLoading(false);
      setProjects(response.data);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const fetchOrganisationMembers = async () => {
    setAddMemberModal(false);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}organisation/users/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      if (response.data) {
        setOrganisationMember(response.data);
      }
      if (response.status === 404) {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const removeMember = async (id) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}organisation/remove-user/${params.id}`,
        {
          userId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setRemoveMemberModal(false);
      fetchOrganisationMembers();
      toast.success("Member Removed");
    } catch {
      toast.error("Something went wrong");
    }
  };

  const deleteProject = async (id, orgId) => {
    setShowConfirmationModal({
      id: id,
      orgId: orgId,
    });
  };

  useEffect(() => {
    setNewRole(memberData?.role);
  }, [memberData]);

  useEffect(() => {
    fetchProjects();
  }, [modalOrg, modal, addMemberModal, projects.length]);

  useEffect(() => {
    if (params.id !== ":id") {
      fetchOrganisationMembers();
      getCurrentUserRole();
    }
  }, [modalOrg]);

  return (
    <>
      <ScreenWrapper>
        <BodyContainer>
          <MainBody>
            <BreadCrumbsHolder>
              <Breadcrumbs separator="›" size="sm">
                <Link color="primary" href="/">
                  <BiHome />
                </Link>
                <Link color="primary" href="/menu/organisation">
                  <Typography>
                    <Typography>Organisations</Typography>
                  </Typography>
                </Link>
                <Typography>
                  {params.id === ":id"
                    ? "All Projects"
                    : `${currentOrg?.name}'s Projects`}
                </Typography>
              </Breadcrumbs>
            </BreadCrumbsHolder>
            {params.id === ":id" ? null : (
              <Heading>
                <div className={"org-name"}>
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      `${
                        currentOrg?.c_organisationLogo
                          ? currentOrg?.c_organisationLogo
                          : currentOrg?.organisationLogo
                      }`
                    }
                    alt={"Organisation Logo"}
                  />
                  <Typography level="title-lg">
                    <>{currentOrg?.name}</>
                  </Typography>
                  &nbsp;&nbsp;
                  {currentRole === "owner" ? (
                    <Button size="sm" onClick={toggleOrg} variant="plain">
                      <PiGear fontSize={"18px"} />
                    </Button>
                  ) : null}
                </div>
                <div>
                  <Button
                    variant="outlined"
                    startDecorator={<AiOutlineUser />}
                    size={"sm"}
                    onClick={() => setHideRightBar(!hideRightBar)}
                  >
                    Manage Users
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="solid"
                    startDecorator={<AiOutlineLock />}
                    size={"sm"}
                    onClick={() => {
                      navigate(`/menu/organisation/${params.id}/secrets`);
                    }}
                  >
                    Vault
                  </Button>
                </div>
                {/*<IoReturnUpBackOutline onClick={() => navigate(-1)} size={30}*/}
                {/*                       style={{marginRight: '10px', cursor: 'pointer'}}/>*/}
              </Heading>
            )}
            <Partition>
              <PartitionTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography level="title-lg">
                    Projects{" "}
                    {projects.length > 0 ? `(${projects.length})` : null}
                  </Typography>
                  <SearchBar
                    value={(val) => setSearchTerm(val)}
                    searchTerm={searchTerm}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: "40px",
                    marginTop: "5px",
                  }}
                >
                  <Segment
                    style={{ marginRight: "10px" }}
                    values={[<CiViewTable />, <PiCardsLight />]}
                    onSegmentChange={(val) =>
                      val === 1 ? toggleTableView(true) : toggleTableView(false)
                    }
                    selectedValue={viewChange ? 1 : 0}
                  />
                  <Segment
                    values={["Active", "InActive"]}
                    fontSize={"1em"}
                    onSegmentChange={(val) =>
                      val === 1 ? setViewActive(false) : setViewActive(true)
                    }
                    selectedValue={viewActive ? 0 : 0}
                  />
                </div>
              </PartitionTitle>
              {!viewChange && params.id !== ":id" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "end",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    variant="solid"
                    color="primary"
                    size="md"
                    startDecorator={<AiOutlineAppstoreAdd />}
                    onClick={() => {
                      setProjectDetails(null);
                      toggle();
                    }}
                  >
                    Add Project
                  </Button>
                </div>
              )}
              {viewChange && (
                <PartitionBody style={{ maxHeight: "75vh" }}>
                  {currentRole === "owner" && params.id !== ":id" && (
                    <Card
                      onClick={() => {
                        setProjectDetails(null);
                        toggle();
                      }}
                    >
                      <p
                        style={{
                          color: "#6e6e6e",
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IoMdAdd /> Create Project
                      </p>
                    </Card>
                  )}
                  {loading ? (
                    <CardSkeletonLoader
                      width={"300px"}
                      heights={"150px"}
                      number={3}
                    />
                  ) : (
                    <>
                      {filteredData?.map(
                        (item, index) =>
                          item.isActive === viewActive && (
                            <Card
                              onClick={() => {
                                navigate(
                                  `/menu/${item._id}/${item.organisation._id}/list`,
                                );
                              }}
                              key={index}
                            >
                              {!item?.creativeDetails[0]?.link ? (
                                <div
                                  style={{
                                    width: "50%",
                                    height: "100%",
                                    borderRadius: "25px",
                                    objectFit: "cover",
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    zIndex: "-1",
                                    background: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "50px",
                                      color: "#fff",
                                      fontWeight: "500",
                                      width: "50%",
                                      height: "45%",
                                      background: "#3e3e49",
                                      textAlign: "center",
                                      borderRadius: "50%",
                                      margin: "auto",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item.name.charAt(0).toUpperCase()}
                                  </div>
                                </div>
                              ) : null}
                              {isVideo(item?.creativeDetails[0]?.link) ? (
                                <video
                                  src={
                                    process.env.REACT_APP_IMAGE_URL +
                                    item?.creativeDetails[0]?.c_link
                                  }
                                  width="100%"
                                  height="100%"
                                  autoPlay
                                  loop
                                  muted
                                />
                              ) : (
                                <>
                                  {item?.creativeDetails[0]?.link ? (
                                    <img
                                      src={
                                        process.env.REACT_APP_IMAGE_URL +
                                        item?.creativeDetails[0]?.c_link
                                      }
                                      alt="img"
                                    />
                                  ) : null}
                                </>
                              )}
                              {isVideo(item?.creative?.link)}
                              {params.id !== ":id" &&
                                currentRole === "owner" && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "12px",
                                      right: "15px",
                                    }}
                                  >
                                    <HoverMenu
                                      options={[
                                        {
                                          label: "Delete",
                                          onClick: () => {
                                            deleteProject(
                                              item._id,
                                              item.organisation._id,
                                            );
                                          },
                                        },
                                        {
                                          label: "Edit",
                                          onClick: () => {
                                            setProjectDetails(item);
                                            toggle();
                                          },
                                        },
                                      ]}
                                      iconSize={20}
                                      top="14px"
                                      left="10px"
                                      width="80px"
                                    />
                                  </div>
                                )}
                              <CardNameHolder>
                                <Typography level="title-md">
                                  <Ellipsis>{item?.name}</Ellipsis>
                                </Typography>
                                <Typography level="body-xs" color="neutral">
                                  <Ellipsis>
                                    {item?.organisation?.name}
                                  </Ellipsis>
                                </Typography>
                              </CardNameHolder>
                            </Card>
                          ),
                      )}
                    </>
                  )}
                </PartitionBody>
              )}
            </Partition>
            <Partition>
              {!viewChange && (
                <PartitionBody style={{ padding: "0" }}>
                  <Table variant="soft" size="md" stickyHeader={true}>
                    <thead>
                      <tr>
                        <th style={{ width: "5%" }}>S no.</th>
                        <th>Project Name</th>
                        <th>Organisation</th>
                        <th width={"30%"}></th>
                        {params.id !== ":id" && currentRole === "owner" && (
                          <th
                            style={{
                              width: "10%",
                            }}
                          >
                            Actions
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData
                        ?.filter((item) => item.isActive === viewActive)
                        .map(
                          (item, index) =>
                            item.isActive === viewActive && (
                              <tr
                                key={index}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <td>{index + 1}</td>
                                {/* <td
                                                                    onClick={() => {
                                                                        navigate(
                                                                            `/menu/${item._id}/${item.organisation._id}/list`,
                                                                        );
                                                                    }}
                                                                >
                                                                    {item
                                                                        ?.creativeDetails[0]
                                                                        ?.link ? (
                                                                        <Avatar
                                                                            size={
                                                                                "lg"
                                                                            }
                                                                            src={
                                                                                process
                                                                                    .env
                                                                                    .REACT_APP_IMAGE_URL +
                                                                                item
                                                                                    ?.creativeDetails[0]
                                                                                    ?.c_link
                                                                            }
                                                                            alt="img"
                                                                        />
                                                                    ) : (
                                                                        <Avatar
                                                                            size={
                                                                                "lg"
                                                                            }
                                                                            variant="outlined"
                                                                        >
                                                                            {item.name
                                                                                .charAt(
                                                                                    0,
                                                                                )
                                                                                .toUpperCase()}
                                                                        </Avatar>
                                                                    )}
                                                                </td> */}
                                <td
                                  onClick={() => {
                                    navigate(
                                      `/menu/${item._id}/${item.organisation._id}/list`,
                                    );
                                  }}
                                >
                                  <Ellipsis>{item.name}</Ellipsis>
                                </td>

                                <td
                                  onClick={() => {
                                    navigate(
                                      `/menu/${item._id}/${item.organisation._id}/list`,
                                    );
                                  }}
                                >
                                  {/* <Ellipsis> */}
                                  {item.organisation.name}
                                  {/* </Ellipsis> */}
                                </td>
                                <td>
                                  {/* <Button variant="outlined" color="primary" size={"sm"}
                                                                startDecorator={<SlCalender/>}
                                                                onClick={() => {
                                                                    navigate(`/menu/calendar/${item._id}`);
                                                                }}
                                                        >
                                                            <Ellipsis>
                                                                {item.name} 's calendar
                                                            </Ellipsis>
                                                        </Button>
                                                        &nbsp; */}
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size={"sm"}
                                    startDecorator={<BiLogoCreativeCommons />}
                                    onClick={() => {
                                      navigate(
                                        `/menu/${item._id}/${item.organisation._id}/list`,
                                      );
                                    }}
                                  >
                                    View Creatives
                                  </Button>
                                </td>

                                {params.id !== ":id" &&
                                  currentRole === "owner" && (
                                    <td>
                                      <>
                                        <Button
                                          size={"sm"}
                                          variant={"soft"}
                                          color={"primary"}
                                          onClick={() => {
                                            setProjectDetails(item);
                                            toggle();
                                          }}
                                        >
                                          <FaRegEdit />
                                        </Button>
                                        &nbsp;
                                        <Button
                                          size={"sm"}
                                          variant={"soft"}
                                          color={"danger"}
                                          onClick={() =>
                                            deleteProject(
                                              item._id,
                                              item.organisation._id,
                                            )
                                          }
                                        >
                                          <MdDeleteOutline />
                                        </Button>
                                        {/*<FaRegEdit style={{marginRight: '10px'}} size={20}*/}
                                        {/*           onClick={*/}
                                        {/*               () => {*/}
                                        {/*                   setProjectDetails(item);*/}
                                        {/*                   toggle();*/}
                                        {/*               }*/}
                                        {/*           }/>*/}
                                        {/*<MdDeleteOutline size={20} onClick={() =>*/}
                                        {/*    deleteProject(item._id, item.organisation._id)*/}
                                        {/*}/>*/}
                                      </>
                                    </td>
                                  )}
                              </tr>
                            ),
                        )}
                    </tbody>
                  </Table>
                </PartitionBody>
              )}
            </Partition>
          </MainBody>
          {params.id === ":id" ? null : (
            <Drawer
              anchor="right"
              size="sm"
              open={hideRightBar}
              onClose={() => setHideRightBar(false)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                {/* Header */}
                <Box
                  sx={{
                    height: "5%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography level="title-md">All Members</Typography>
                </Box>

                {/* Content */}
                <Box
                  sx={{
                    height: "90%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    textAlign: "center",
                    p: 2,
                  }}
                >
                  <Divider />
                  {organisationMember && organisationMember.length === 0 ? (
                    <Typography>No Members</Typography>
                  ) : (
                    organisationMember.map((item) => (
                      <UserInfoCard
                        key={item.email}
                        activeCard={activeCard}
                        setActiveCard={setActiveCard}
                        user={item}
                        cardRole={
                          item.role === "owner"
                            ? "Admin"
                            : item.role === "member"
                              ? "User"
                              : item.role === "moderator"
                                ? "Moderator"
                                : item.role
                        }
                        canRemove={
                          (currentRole === "owner" &&
                            item.role !== "owner" &&
                            item.email !== user.userName) ||
                          (currentRole === "owner" &&
                            item.role === "owner" &&
                            item.email !== user.userName) ||
                          (currentRole === "moderator" &&
                            item.role === "member" &&
                            item.email !== user.userName) ||
                          (currentRole === "moderator" &&
                            item.role === "moderator" &&
                            item.email !== user.userName)
                        }
                        onRemove={() => {
                          setMemberData(item);
                          setRemoveMemberModal(true);
                        }}
                        onEdit={() => {
                          setMemberData(item);
                          setEditMemberRoleModal(true);
                        }}
                      />
                    ))
                  )}
                </Box>

                {/* Footer */}
                <Box
                  sx={{
                    height: "5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {currentRole === "member" ? null : (
                    <Button
                      size="sm"
                      startDecorator={<AiOutlineUserAdd />}
                      variant="outlined"
                      color="warning"
                      onClick={addMemberToggle}
                    >
                      Add Member
                    </Button>
                  )}
                </Box>
              </Box>
            </Drawer>
          )}
        </BodyContainer>
        <Toaster />
        {showConfirmationModal && (
          <AlertModal
            title="Delete Project !"
            subtitle="Do you really want to remove the project?"
            onConfirm={async () => {
              const Delete = api.deleteProject(
                showConfirmationModal.id,
                showConfirmationModal.orgId,
              );
              if (Delete) {
                setShowConfirmationModal(false);
                toast.success("Project Deleted");
                setProjects(
                  projects.filter(
                    (item) => item._id !== showConfirmationModal.id,
                  ),
                );
              } else {
                setShowConfirmationModal(false);
                toast.error("Problem Deleting Project");
              }
            }}
            onCancel={() => {
              setShowConfirmationModal(false);
            }}
          />
        )}
      </ScreenWrapper>
      {removeMemberModal ? (
        <ModalContainer>
          <CloseButton
            onClick={() => {
              setRemoveMemberModal(false);
            }}
          >
            <IoIosCloseCircleOutline />
          </CloseButton>
          <Modal>
            <ModalHeader>
              <h3 className="modal_header-title">
                Remove <b>{removeDomainNameFromEmail(memberData.email)}</b>
              </h3>
              <CloseButton onClick={() => setRemoveMemberModal(false)}>
                <IoIosCloseCircleOutline />
              </CloseButton>
            </ModalHeader>
            <ModalBody>
              <p>Removing a member from the Organisation</p>
              <p>Email : {memberData.email}</p>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => removeMember(memberData._id)}>
                Remove
              </Button>
            </ModalFooter>
          </Modal>
        </ModalContainer>
      ) : null}
      <EditMemberRoleModal
        show={editMemberRoleModal}
        close={() => {
          setEditMemberRoleModal(false);
          fetchOrganisationMembers();
        }}
        memberData={memberData}
        currentRole={currentRole}
        organisationId={params.id}
      />
      <CreateProject
        show={modal}
        close={toggle}
        organisation={params.id !== ":id" ? currentOrg : null}
        project={projectDetails}
      />
      <AddOrgMember
        show={addMemberModal}
        currentRole={currentRole}
        close={(val) => (val ? fetchOrganisationMembers() : addMemberToggle())}
        id={params.id}
        organisation={currentOrg}
      />
      <CreateOrg show={modalOrg} close={toggleOrg} organisation={currentOrg} />
    </>
  );
};

export default ViewProjects;
