import axios from "axios";
import { toast } from "react-hot-toast";
import React, { useEffect, useState } from "react";
import DefaultSelect from "../../../Components/default-select/default-select";
import { isValidEmail } from "../../../Components/Helpers/helper";
import { DialogTitle, Modal, ModalDialog } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import Input from "@mui/joy/Input";
import Ellipsis from "../../../Components/Ellipsis/Ellipsis";

const AddOrgMember = ({ show, close, id, currentRole, organisation }) => {
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setEmail("");
        setRole("");
    }, [close]);

    const addMember = async () => {
        if (email === "" || !isValidEmail(email)) {
            return toast.error("Please enter a valid email");
        } else if (role === "") {
            return toast.error("Role is required");
        }
        setLoading(true);

        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}organisation/user/add/${id}`,
                {
                    email: email,
                    role: role,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
            toast.success("User Added Successfully");
            setLoading(false);
            close(true);
        } catch (e) {
            setLoading(false);
            toast("Something went wrong");
        }
    };

    return (
        <Modal open={show} onClose={close}>
            <ModalDialog sx={{ maxWidth: "40%" }}>
                <DialogTitle>
                    Invite Member To Join{" "}
                    <Ellipsis>{organisation?.name}</Ellipsis> Organisation
                </DialogTitle>
                <Divider />
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        addMember();
                    }}
                >
                    <Stack spacing={2}>
                        <FormControl>
                            <Input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <DefaultSelect
                                onSelect={(selectedOption) => {
                                    setRole(selectedOption.value);
                                }}
                                options={
                                    currentRole === "moderator"
                                        ? [
                                              {
                                                  value: "member",
                                                  label: "User",
                                                  explanation:
                                                      "User Can access and use the core features of the platform. - Typically, users can't access administrative or moderation functions.",
                                              },
                                              {
                                                  value: "moderator",
                                                  label: "Moderator",
                                                  explanation:
                                                      "Moderator Can access and use the core features of the platform. - Typically, users can't access administrative or moderation functions.",
                                              },
                                          ]
                                        : [
                                              {
                                                  value: "member",
                                                  label: "User",
                                                  explanation:
                                                      "User Can access and use the core features of the platform. - Typically, users can't access administrative or moderation functions.",
                                              },
                                              {
                                                  value: "moderator",
                                                  label: "Moderator",
                                                  explanation:
                                                      "Moderator Can add and remove users.",
                                              },
                                              {
                                                  value: "owner",
                                                  label: "Admin",
                                                  explanation:
                                                      "Admin has Full control over the entire platform. - User management, including the ability to add, modify, or remove users.",
                                              },
                                          ]
                                }
                                placeholder={"Select Role"}
                            />
                        </FormControl>
                        <Divider />
                        <Button type="submit" loading={loading}>
                            Send Invite
                        </Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal>
    );
};
export default AddOrgMember;
