import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { addBulkMembersInOrganisation } from "../../../api";
import industriesObjects from "../../../constants/industries";
import {
  containsOnlySpaces,
  isValidEmail,
} from "../../../Components/Helpers/helper";
import FileUpload from "../../../Components/FileInput/file-input";
import EmailRoleForm from "../../../Components/UserRoleForm/UserRoleForm";
import {
  Button,
  Typography,
  Select,
  Option,
  Modal,
  ModalDialog,
  ModalClose,
  FormControl,
  FormLabel,
  Input,
} from "@mui/joy";

const CreateOrg = ({ show, close, organisation }) => {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [organistionId, setOrganistionId] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOrgType, setSelectedOrgType] = useState(null);
  const navigate = useNavigate();

  const rolesOptions = [
    {
      value: "member",
      label: "User",
      explanation:
        "User Can access and use the core features of the platform. - Typically, users can't access administrative or moderation functions.",
    },
    {
      value: "moderator",
      label: "Moderator",
      explanation: "Moderator Can add and remove users.",
    },
    {
      value: "owner",
      label: "Admin",
      explanation:
        "Admin has Full control over the entire platform. - User management, including the ability to add, modify, or remove users.",
    },
  ];
  useEffect(() => {
    if (organisation) {
      setName(organisation?.name || "");
      setDescription(organisation?.description || "");
      setEmail(organisation?.organisationEmail || "");
      setOrganistionId(organisation._id || "");
      setStep(1);
    } else {
      setName("");
      setDescription("");
      setEmail("");
      setFile(null);
      setOrganistionId("");
      setStep(1);
    }
  }, [show]);

  const handleFilesSelected = (files) => {
    if (files[0]?.size > 50 * 1024 * 1024) {
      toast.error("File size exceeds 50 MB. Please choose a smaller file.");
      return;
    }
    setFile(files[0]);
  };

  const inputChecks = (update) => {
    if (!name.trim()) {
      toast.error("Name is required");
      return true;
    }
    if (!description.trim()) {
      toast.error("Description is required");
      return true;
    }
    if (!email.trim() || !isValidEmail(email)) {
      toast.error("Enter a valid Email");
      return true;
    }
    if (!update) {
      if (!selectedOrgType) {
        toast.error("Organisation type is required");
        return true;
      }
      if (!file) {
        toast.error("Logo is required");
        return true;
      }
    }
    return false;
  };

  const createOrganisations = async () => {
    if (inputChecks(!!organisation)) return;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("organisationEmail", email);
    formData.append("logo", file);
    formData.append("organisationType", selectedOrgType || "");

    setLoading(true);
    try {
      const url = organisation
        ? `${process.env.REACT_APP_API_URL}organisation/${organisation._id}`
        : `${process.env.REACT_APP_API_URL}organisation`;

      const method = organisation ? axios.put : axios.post;

      await method(url, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then((res) => {
        if (res.data) {
          setOrganistionId(res.data._id);
          if (!organisation) {
            setStep(2);
          } else {
            close();
          }
        }
      });

      toast.success(
        `Organization ${organisation ? "Updated" : "Created"} Successfully!`,
      );
      setName("");
      setDescription("");
      setEmail("");
      setFile(null);
      setSelectedOrgType(null);
    } catch (e) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const AddMultipleMembers = async (users) => {
    if (!users) return close();

    const response = await addBulkMembersInOrganisation(organistionId, users);
    if (response) {
      toast.success("Members Added Successfully!");
      navigate(`/menu/project/${organistionId}`);
      close();
      setStep(1);
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      createOrganisations();
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Typography level="h3">
              {organisation
                ? `Settings for ${organisation?.name}`
                : "Create Organisation"}
            </Typography>
            <ModalClose onClick={close}>
              <IoIosCloseCircleOutline />
            </ModalClose>

            <FormControl>
              <FormLabel>Organisation Name</FormLabel>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Organisation Description</FormLabel>
              <Input
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Organisation Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>

            {!organisation && (
              <FormControl>
                <FormLabel>Organisation Type</FormLabel>
                <Select
                  placeholder="Choose Organisation Type"
                  value={selectedOrgType}
                  onChange={(event, newValue) => setSelectedOrgType(newValue)}
                >
                  {industriesObjects.map((industry) => (
                    <Option key={industry.value} value={industry.value}>
                      {industry.label}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl>
              <FormLabel>
                {organisation
                  ? "Update Organisation Logo"
                  : "Upload Organisation Logo"}
              </FormLabel>
              <FileUpload
                onFilesSelected={handleFilesSelected}
                allowedTypes={["image/png", "image/jpeg"]}
              />
            </FormControl>

            <Button onClick={createOrganisations} loading={loading}>
              {organisation ? "Update" : "Create"}
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Typography level="h3">Add Members to Organisation</Typography>
            <ModalClose onClick={close}>
              <IoIosCloseCircleOutline />
            </ModalClose>
            <EmailRoleForm onSubmit={AddMultipleMembers} roles={rolesOptions} />
          </>
        );
      default:
        return null;
    }
  };

  return show ? (
    <Modal open={show} onClose={close}>
      <ModalDialog>{renderStepContent()}</ModalDialog>
    </Modal>
  ) : null;
};

export default CreateOrg;
