import React, { useState } from "react";
import axios from "axios";
import {
  Modal,
  ModalDialog,
  Typography,
  Select,
  Option,
  Button,
  Sheet,
  CircularProgress,
} from "@mui/joy";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { toast } from "react-hot-toast";

const EditMemberRoleModalProject = ({
  open,
  onClose,
  memberData,
  roles,
  projectId,
  refreshMembers,
}) => {
  const [newRole, setNewRole] = useState(memberData?.role || "");
  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    if (!newRole || loading) return;
    setLoading(true);

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}project/updateUser/${projectId}`,
        { userId: memberData._id, role: newRole },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      toast.success("Member role updated successfully");
      refreshMembers(); // Refresh the member list after update
      onClose(); // Close modal
    } catch (error) {
      toast.error("Failed to update role");
      console.error(error.response || error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" sx={{ width: 400 }}>
        <Sheet sx={{ p: 2 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography level="title-md">
              Edit <b>{memberData?.firstName + memberData?.lastName}</b> Role
            </Typography>
            <IoIosCloseCircleOutline
              size={24}
              style={{ cursor: "pointer" }}
              onClick={onClose}
            />
          </div>

          <Typography level="body-sm" sx={{ mt: 1 }}>
            Email: {memberData?.email}
          </Typography>

          <Select
            sx={{ mt: 2, width: "100%" }}
            placeholder="Select Member Role"
            value={newRole}
            onChange={(e, newValue) => setNewRole(newValue)}
          >
            {roles.map((role) => (
              <Option key={role.value} value={role.value}>
                {role.label}
              </Option>
            ))}
          </Select>

          <Button
            sx={{ mt: 2, width: "100%" }}
            onClick={handleUpdate}
            disabled={loading}
          >
            {loading ? <CircularProgress size="sm" /> : "Update"}
          </Button>
        </Sheet>
      </ModalDialog>
    </Modal>
  );
};

export default EditMemberRoleModalProject;
