import React, { useState } from "react";
import axios from "axios";
import {
  Modal,
  ModalDialog,
  Typography,
  Button,
  Sheet,
  CircularProgress,
} from "@mui/joy";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { toast } from "react-hot-toast";

const RemoveMemberModalProject = ({
  open,
  onClose,
  memberData,
  projectId,
  refreshMembers,
}) => {
  const [loading, setLoading] = useState(false);

  const handleRemove = async () => {
    if (loading) return;
    setLoading(true);

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}project/removeUser/${projectId}`,
        { userId: memberData._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );

      toast.success("Member removed successfully");
      refreshMembers(); // Refresh the member list
      onClose(); // Close modal
    } catch (error) {
      toast.error("Failed to remove member");
      console.error(error.response || error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" sx={{ width: 400 }}>
        <Sheet sx={{ p: 2 }}>
          {/* Header */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography level="title-md">
              Remove <b>{memberData.firstName + " " + memberData.lastName}</b>
            </Typography>
            <IoIosCloseCircleOutline
              size={24}
              style={{ cursor: "pointer" }}
              onClick={onClose}
            />
          </div>

          {/* Confirmation Message */}
          <Typography level="body-sm" sx={{ mt: 1 }}>
            Are you sure you want to remove this member from the project?
          </Typography>
          <Typography level="body-sm" sx={{ fontWeight: "bold", mt: 1 }}>
            Email: {memberData?.email}
          </Typography>

          {/* Remove Button */}
          <Button
            sx={{ mt: 2, width: "100%" }}
            color="danger"
            onClick={handleRemove}
            disabled={loading}
          >
            {loading ? <CircularProgress size="sm" /> : "Remove"}
          </Button>
        </Sheet>
      </ModalDialog>
    </Modal>
  );
};

export default RemoveMemberModalProject;
