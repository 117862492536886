import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  max-width: 300px;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
`;

const InputBox = styled.input`
  width: 45px;
  height: 45px;
  text-align: center;
  font-size: 20px;
  margin: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;

  &:focus {
    border-color: blue;
  }
`;

const OtpInput = ({ length, onComplete, inputStyle }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = Array.from({ length }, () => createRef());

  const handleChange = (element, index) => {
    const next = inputRefs[index + 1];
    if (element.value.length > 0 && next) {
      next.current.focus();
    }
    setOtp(otp.map((item, idx) => (idx === index ? element.value : item)));
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text").slice(0, length);
    const newOtp = pasteData.split("").slice(0, length);
    if (pasteData.length === length) {
      const newOtp = pasteData.split("");
      newOtp.forEach((char, idx) => {
        if (inputRefs[idx]) {
          inputRefs[idx].current.value = char;
        }
      });
      setOtp(newOtp);
      onComplete(pasteData);
    }
    e.preventDefault();
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      const prev = inputRefs[index - 1];
      if (prev) {
        prev.current.focus();
      }
    }
  };

  useEffect(() => {
    if (otp.every((num) => num !== "")) {
      onComplete(otp.join(""));
    }
  }, [otp, onComplete]);

  return (
    <Container>
      {otp.map((data, index) => (
        <InputBox
          key={index}
          ref={inputRefs[index]}
          maxLength="1"
          value={data}
          onChange={(e) => handleChange(e.target, index)}
          onFocus={(e) => e.target.select()}
          onPaste={handlePaste}
          style={inputStyle}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      ))}
    </Container>
  );
};

export default OtpInput;
