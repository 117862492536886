import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import "./board.css";
import {
    IoIosCloseCircleOutline,
    IoLogoFacebook,
    IoLogoInstagram,
    IoLogoLinkedin,
    IoLogoTwitter,
    IoLogoYoutube,
    IoMdLink,
} from "react-icons/io";

import {
    AiFillCaretDown,
    AiFillCaretUp,
    AiOutlineBell,
    AiOutlineCloudDownload,
    AiOutlineCopy,
    AiOutlineEdit,
    AiOutlineUpload, AiOutlineUser
} from "react-icons/ai";
import {
    BodyWrapper,
    Button2,
    Buttoni,
    ButtonWrapper,
    CaptionAiButtonContainer,
    CaptionBox,
    CaptionContainerBox,
    ChatImagePreviewWrapper,
    CloseIcon,
    CompareCardDescription,
    CompareCardImageWrapper,
    ComparedCard,
    CompareImage,
    DownHeader,
    FloatingSideMenu,
    FloatingSideMenuContent,
    HeaderWrapper,
    ImagePreview,
    LeftSide,
    PublishModal,
    PublishModalBody,
    PublishModalInnerwrapper,
    RightSide,
    RoundNotification,
    SocialMedia,
    Table,
    Tbody,
    Td,
    Thead,
    Toast,
    ToastClose,
    ToastCounterCircle,
    Tr,
    UpperHeader,
    UpperHeaderWrapper,
    VideoImageWrapper,
    VideoTd,
    Wrapper,
} from "./board.style";
import {
    extractNameFromEmail,
    formatDate,
    formatMonthYear,
    monthDateToMonthName,
} from "../../Components/Helpers/helper";
import {toast, Toaster} from "react-hot-toast";
import CreateCreative from "./creatives/create-creative/create-creative";
import CreativeRevision from "./creatives/creative-revision/creative-revision";
import SlideBar from "./slideBar/slideBar";

import {
    CloseButton,
    InputWrapper,
    Modal,
    ModalBody,
    ModalContainer,
    ModalFooter,
    ModalHeader
} from "../../Components/UI/modal.style";
import TextInput from "../../Components/custome-tags/TextInput/textInput";
import io from "socket.io-client";
import AlertModal from "../../Components/Alert-modal/alert-modal";
import Api from "../../api";
import api, {removeCreativeFromProject} from "../../api";
import TableSkeletonLoader from "../../Components/Loaders/Table-skeleton-loader";
import Chat from "../../Components/Chat/chat";
import {MdCompare, MdEditCalendar, MdPublish} from "react-icons/md";
import {RiAiGenerate, RiDeleteBin6Line} from "react-icons/ri";
import AiContentForm from "../../Components/Ai-Content-Form/ai-content-form";
import {PiMagicWandThin} from "react-icons/pi";
import { BsFlag} from "react-icons/bs";
import {IoChatbubbleEllipsesOutline} from "react-icons/io5";
import SecondaryButton from "../../Components/Buttons/Secondary-Button/secondary-button";
import {BiHome, BiSolidChevronRight} from "react-icons/bi";
import Tooltip from "../../Components/Tool-Tip/tool-tip";
import Ellipsis from "../../Components/Ellipsis/Ellipsis";
import Button from "@mui/joy/Button";
import {Breadcrumbs, Chip, Typography} from "@mui/joy";
import Link from "@mui/joy/Link";
import {BreadCrumbsHolder} from "../../Components/UI/basic.style";

const audio = new Audio('https://s3.amazonaws.com/appforest_uf/f1598429253106x343351561325749800/apple_msg_tone.mp3');

const Board = () => {
        const [videos, setVideos] = useState([]);
        const [isLoading, setIsLoading] = useState(false);
        const [error, setError] = useState(null);
        const [userRole, setUserRole] = useState(localStorage.getItem("userRole"));
        const path = window.location.origin;
        const [approveAlert, setApproveAlert] = useState(false)
        const [socket, setSocket] = useState(null);
        const [currentProject, setCurrentProject] = useState(null);
        const [showDeleteCreativeConfirmModal, setShowDeleteCreativeConfirmModal] = useState(false);
        const [collapsedRows, setCollapsedRows] = useState({});
        const [captionLoading, setCaptionLoading] = useState(false);
        const [viewChatBox, setViewChatBox] = useState(false);

        const [totalNumberVideos, setTotalNumberVideos] = useState({
            pending: 0,
            approved: 0,
            rejected: 0,
        });

        const [socialMedia, setSocialMedia] = useState({
            facebook: false,
            instagram: false,
            twitter: false,
            youtube: false,
            linkedin: false,
        });
        const [activeButton, setActiveButton] = useState('pending');
        const [publishModal, setPublishModal] = useState(false);
        const [selectedVideoId, setSelectedVideoId] = useState(null);
        const [previewVideo, setPreviewVideo] = useState(false);
        const [flag, setFlag] = useState(null);
        const [openCompareModal, setOpenCompareModal] = useState(false);
        const params = useParams();
        const [creativeId, setCreativeId] = useState()
        const [modalView, setModalView] = useState(false)
        const [revisionModal, setRevisionModal] = useState(false)
        const [previewImage, setPreviewImage] = useState(false)
        const [previewImageSrc, setPreviewImageSrc] = useState(null)
        const [rejectAlert, setRejectAlert] = useState(false)
        const [selectedVideo, setSelectedVideo] = useState({
            description: "",
        })
        const Toggle = () => setModalView(!modalView)
        const ToggleRevision = () => setRevisionModal(!revisionModal)
        const TogglePreviewImage = () => setPreviewImage(!previewImage)
        const [videoNotificationCount, setVideoNotificationCount] = useState({});
        const [activeToasts, setActiveToasts] = useState({});
        const [AiInputModal, setAiInputModal] = useState(false);
        const ToggleAiInputModal = () => setAiInputModal(!AiInputModal)
        const [showUserManageModal, setShowUserManageModal] = useState(false);


        const updateCreativeType = (type, data) => {
            if (type.target.value === 'rejected') {
                if (!data.description) {
                    toast.error("Please enter feedback");
                    return false;
                }
            }
            try {
                axios.put(
                    `${process.env.REACT_APP_API_URL}creative/rate/${data._id}`,
                    {
                        approved: type.target.value,
                        project: data.project,
                        description: data.description,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        }
                    }
                ).then(e => {
                    toast.success(`Content ${type.target.value} successfully`);
                    setPreviewVideo(false);
                    setRejectAlert(false);
                    setApproveAlert(false)
                })
            } catch {
                toast.error("Something went wrong");
            }
        }


        const rewriteCaption = (creativeId, mutationType) => {
            setCaptionLoading(true)
            api.ReWriteCaption(creativeId, mutationType).then(e => {
                toast.success("Caption Rewritten Successfully");
                console.log(e.caption)
                setPreviewVideo({
                    ...previewVideo,
                    caption: e.caption
                })
                setCaptionLoading(false)
            }).catch(e => {
                toast.error("Something went wrong");
                setCaptionLoading(false)
            })
        }


        const deleteCreative = (id) => {
            api.removeCreativeFromProject(id).then(e => {
                toast.success("Content deleted successfully");
                setPreviewVideo(false);
                setRejectAlert(false);
                setApproveAlert(false)
            }).catch(e => {
                toast.error("Something went wrong");
            })
        }

        const fetchData = async () => {
            // Set isLoading to true after a delay of 1 second
            const loaderTimeout = setTimeout(() => {
                setIsLoading(true);
            }, 600);

            setError(null);

            try {
                const userProjectResponse = await Api.fetchUserProjectById(params.id);
                setCurrentProject(userProjectResponse);

                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}creative/${params.id}?approved=${activeButton}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                setIsLoading(false);
                setVideos(response?.data.creatives);
                setTotalNumberVideos({
                    pending: response?.data.count.pending,
                    approved: response?.data.count.approved,
                    rejected: response?.data.count.rejected,
                });
                clearTimeout(loaderTimeout);

            } catch (error) {
                setIsLoading(false);
                toast.error("Something went wrong");
                clearTimeout(loaderTimeout);
            }
        };


        const getUserRole = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}project/currentUser/${params.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
                setUserRole(response?.data?.role);
            } catch (err) {
                setIsLoading(false);
                toast.error("Something went wrong");
            }
        }
        useEffect(() => {
            getUserRole()
            fetchData();
        }, [modalView, previewVideo, activeButton, revisionModal, publishModal]);

        useEffect(() => {
            const handleVideoParam = () => {
                const searchParams = new URLSearchParams(window.location.search);
                if (searchParams.get("video")) {

                    const selectedVideo = videos.filter(
                        (video) => video._id === searchParams.get("video")
                    );
                    if (selectedVideo.length > 0) {
                        setPreviewVideo(selectedVideo[0]);
                    }
                }
            };
            if (videos.length > 0) {
                handleVideoParam();
            }
        }, [videos]);


        useEffect(() => {
            const newSocket = io.connect(process.env.REACT_APP_API_URL, {
                transportOptions: {
                    polling: {
                        extraHeaders: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                }
            });
            setSocket(newSocket);
        }, [params.id]);


        useEffect(() => {
            if (!socket) {
                return;
            }
            setTimeout(() => {
                socket.emit('joinProjectRoom', {projectId: params.id});
            }, 1000);

            socket.on('newChatNotification', (data) => {
                if (data.sender === localStorage.getItem('userName')) {
                    return;
                }
                if (previewVideo && previewVideo._id === data.videoId) {
                    setVideoNotificationCount(prevCount => ({...prevCount, [data.videoId]: 0}));
                    return;
                }


                audio.play().catch((error) => {
                    console.log('Audio play failed due to', error);
                });

                // const toastId = toast((t) => (
                //     <Toast>
                //         <span>New message in video {data.videoId}</span>
                //         <ToastClose onClick={() => toast.dismiss(toastId)}>
                //             <IoIosCloseCircleOutline/>
                //         </ToastClose>
                //     </Toast>
                // ), {
                //     duration: Infinity,
                //     id: data.videoId, // Optional: use videoId as the toast id to prevent duplicate toasts for the same video
                //     onClick: () => {
                //         console.log('Clicked');
                //         toast.dismiss(toastId);
                //     }
                // });


                videos.forEach((video) => {
                        if (video._id === data.videoId) {
                            video.notification = true;
                        }
                    }
                );


                setVideos([...videos]);


                setVideoNotificationCount(prevCount => {
                    const newCount = (prevCount[data.videoId] || 0) + 1;
                    return {...prevCount, [data.videoId]: newCount};
                });


                // Dismiss the existing toast if there is one
                if (activeToasts[data.videoId]) {
                    toast.dismiss(activeToasts[data.videoId]);
                }

                const toastId = toast((t) => (
                    <Toast>
                        <span>New messages in video {data.videoId} </span>
                        <ToastCounterCircle>
                            <AiOutlineBell size={20}/>
                            {videoNotificationCount[data.videoId] || 1}
                        </ToastCounterCircle>
                        <ToastClose onClick={() => toast.dismiss(toastId)}> <IoIosCloseCircleOutline/></ToastClose>
                    </Toast>
                ), {
                    duration: Infinity,
                    id: data.videoId,
                });
                setActiveToasts(prevToasts => ({...prevToasts, [data.videoId]: toastId}));
                console.log(`New message in video ${data.videoId} from ${data.sender}: ${data.message}`);
            });
            return () => {
                socket.off('newChatNotification');
            };

        }, [socket, params.id, videos, previewVideo, videoNotificationCount]);

        const submitFlag = async (item) => {
            console.log("Submitted For flagging", item)
            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_API_URL}creative/flag/${item}`,
                    {project: params.id},
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );

                if (response.data) {
                    fetchData();
                    setFlag(null);
                    setSelectedVideoId(null);
                    setPublishModal(false);
                    setPreviewVideo(false);
                }
            } catch (error) {
                fetchData();
                setFlag(null);
                setSelectedVideoId(null);
                setPublishModal(false);
                setPreviewVideo(false);
            }
        };

        if (error) {
            return <p>{error}</p>;
        }


        function extractBaseUrl(url) {
            const urlObject = new URL(url);
            return urlObject.origin + urlObject.pathname;
        }

        function isVideo(url) {
            const extractUrl = extractBaseUrl(url);
            // Regular expression pattern to match video file extensions
            let pattern = /\.(mp4|ogg|webm|mov|flv|avi|wmv|mpeg|mpg)$/i;
            return pattern.test(extractUrl);
        }

        const openPublishModal = (video) => {
            setPublishModal(true);
            setSelectedVideoId(video);
        };

        const publishVideo = async (video) => {
            console.log("Publishing Video", socialMedia)
            if (
                !socialMedia.facebook &&
                !socialMedia.instagram &&
                !socialMedia.twitter &&
                !socialMedia.youtube &&
                !socialMedia.linkedin
            ) {
                toast.error("Please enter at least one social media link");
                return;
            }
            const response = await axios.patch(
                `${process.env.REACT_APP_API_URL}creative/publish/${video._id}`,
                {
                    facebook: socialMedia.facebook,
                    instagram: socialMedia.instagram,
                    twitter: socialMedia.twitter,
                    youtube: socialMedia.youtube,
                    linkedin: socialMedia.linkedin,
                    project: video.project,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            if (response.data) {
                await fetchData();
                setPublishModal(false);
                setPreviewVideo(false);
                setSelectedVideoId(null);
                setSocialMedia({
                    facebook: false,
                    instagram: false,
                    twitter: false,
                    youtube: false,
                    linkedin: false,
                });
            }
        };


        const groupedVideos = videos.reduce((groups, video) => {
            const key = formatMonthYear(video.createTime);
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(video);
            return groups;
        }, {});

        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                event.target.value = 'rejected'
                if (rejectAlert) {
                    updateCreativeType(event, selectedVideo)
                }
            }
        };


        return (<>
                <Wrapper>
                    <BreadCrumbsHolder>
                        <Breadcrumbs separator="›" size='sm'>
                            <Link color="primary" href="/">
                                <BiHome/>
                            </Link>
                            <Link color="primary" href="/menu/organisation">
                                <Typography>
                                    <Typography>Organisations</Typography>
                                </Typography>
                            </Link>
                            <Link color="primary" href={`/menu/project/:id`}>
                                <Typography>
                                    <Typography>
                                        All Projects
                                    </Typography>
                                </Typography>
                            </Link>
                            <Typography>
                                <Typography>
                                    {currentProject?.name}'s Board
                                </Typography>
                            </Typography>
                        </Breadcrumbs>
                    </BreadCrumbsHolder>
                    <UpperHeaderWrapper>
                        <UpperHeader>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <h1 style={{
                                    fontSize: '24px',
                                    fontWeight: '400',
                                    color: '#54289a',
                                }}
                                >
                                    <Ellipsis>{currentProject?.name}</Ellipsis>

                                </h1>
                            </div>
                            <Button variant='outlined' startDecorator={
                                <AiOutlineUser/>
                            } size={'sm'} onClick={() => setShowUserManageModal(!showUserManageModal) }>
                                Manage Users
                            </Button>
                            {/*<IoReturnUpBackOutline onClick={() => navigate(-1)} size={30}*/}
                            {/*                       style={{marginRight: '10px', cursor: 'pointer'}}/>*/}
                        </UpperHeader>
                    </UpperHeaderWrapper>
                    <HeaderWrapper>
                        <DownHeader>
                            <Button2
                                active={activeButton === "pending"}
                                onClick={() => {
                                    setActiveButton("pending");
                                }}
                            >
                                Fresh Content
                                {totalNumberVideos.pending > 0 && (
                                    <RoundNotification>
                                        {" "}
                                        {totalNumberVideos.pending}
                                    </RoundNotification>
                                )}
                            </Button2>
                            <Button2
                                active={activeButton === "rejected"}
                                onClick={() => {
                                    setActiveButton("rejected");
                                }}
                            >
                                Rejected
                                {totalNumberVideos.rejected > 0 && (
                                    <RoundNotification>
                                        {" "}
                                        {totalNumberVideos.rejected}
                                    </RoundNotification>
                                )}
                            </Button2>

                            <Button2
                                active={activeButton === "approved"}
                                onClick={() => {
                                    setActiveButton("approved");
                                }}
                            >
                                Approved
                                {totalNumberVideos.approved > 0 && (
                                    <RoundNotification>
                                        {" "}
                                        {totalNumberVideos.approved}
                                    </RoundNotification>
                                )}
                            </Button2>

                            <Button2
                                active={activeButton === "published"}
                                onClick={() => {
                                    setActiveButton("published");
                                }}
                            >
                                Published
                            </Button2>
                            <div style={
                                {
                                    position: "absolute",
                                    top: '25px',
                                    right: 5,
                                }
                            }>
                                {userRole === "admin" || userRole === "creator" ? (
                                    <Button
                                        size="md"
                                        startDecorator={<AiOutlineUpload/>}
                                        style={{
                                            marginRight: "28px",
                                        }}
                                        onClick={() => Toggle()
                                        }
                                    >
                                        Upload Content
                                    </Button>
                                ) : null}
                            </div>
                        </DownHeader>
                    </HeaderWrapper>
                    <BodyWrapper>
                        {videos.length === 0 ? (
                            <img
                                style={{
                                    borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
                                    width: "400px",
                                    marginTop: "6rem",
                                }}
                                src={`${path}/empty.jpeg`}
                                alt='empty.jpeg'
                            />
                        ) : (
                            <Table>
                                <Thead>
                                    <Tr>
                                        <th>S.No.</th>
                                        <th>Title</th>
                                        <th>Feedback</th>
                                        <th>Revisions</th>
                                        <th>Content</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        {activeButton === "published" ? (
                                            <th>Social Media</th>
                                        ) : (
                                            userRole === 'user' ? null : <th>Action</th>
                                        )}
                                        {activeButton === "published" ? <th>Action</th> : null}
                                    </Tr>
                                </Thead>
                                {isLoading ? (
                                        <TableSkeletonLoader numRows={10} numCells={8}/>)
                                    : (
                                        <Tbody>
                                            {Object.entries(groupedVideos)?.map(([monthYear, videosInMonth], index) => (
                                                <>
                                                    <Tr key={index} onClick={() => {
                                                        setCollapsedRows(prevState => ({
                                                            ...prevState,
                                                            [monthYear]: !prevState[monthYear]
                                                        }));
                                                    }}
                                                        style={{
                                                            cursor: "pointer",
                                                            backgroundColor: "#d9d8d8",
                                                            color: "#54289a",
                                                            fontWeight: "bold",
                                                            fontsize: "20px",
                                                        }}
                                                    >

                                                        <Td colSpan="4" style={{
                                                            textAlign: "left",
                                                            paddingLeft: "5rem",
                                                        }}>
                                                            {monthDateToMonthName(monthYear)}
                                                        </Td>
                                                        <Td colspan="1">
                                                            Videos:{videosInMonth.filter((video) => isVideo(video.link) === true).length}
                                                        </Td>
                                                        <Td colspan="1">
                                                            Static:{videosInMonth.filter((video) => isVideo(video.link) === false).length}
                                                        </Td>
                                                        <Td colSpan="1">
                                                            Total:{videosInMonth.length}
                                                        </Td>
                                                        {activeButton === "published" ? (
                                                            <Td colSpan="2">
                                                                {collapsedRows[monthYear] ? (
                                                                    <AiFillCaretDown size={20}/>
                                                                ) : (
                                                                    <AiFillCaretUp size={20}/>
                                                                )}
                                                            </Td>) : (
                                                            <Td colSpan="1">
                                                                {collapsedRows[monthYear] ? (
                                                                    <AiFillCaretDown size={20}/>
                                                                ) : (
                                                                    <AiFillCaretUp size={20}/>
                                                                )}
                                                            </Td>
                                                        )}
                                                    </Tr>
                                                    {videosInMonth.map((video, index) => (
                                                        <Tr key={video._id} active={video.flagged}
                                                            isCollapsed={collapsedRows[monthYear]}>
                                                            <Td
                                                                style={{
                                                                    fontWeight: 100,
                                                                    width: "70px",
                                                                }}
                                                            >
                                                                {index + 1}
                                                            </Td>
                                                            <Td style={{
                                                                width: "200px"
                                                            }}>
                                                                {/*<p style={{*/}
                                                                {/*    fontSize: '12px',*/}
                                                                {/*    color: '#858585 ',*/}
                                                                {/*    textAlign: 'left'*/}
                                                                {/*}}>{formatDate(video.createTime)}</p>*/}
                                                                <p
                                                                    style={{
                                                                        margin: "0",
                                                                        padding: "0",
                                                                        textAlign: "left",
                                                                    }}
                                                                >
                                                                    {video.title}
                                                                </p>
                                                            </Td>
                                                            <Td
                                                                style={{
                                                                    cursor: "pointer",
                                                                    maxWidth: "300px",
                                                                }}
                                                            >
                                                                {
                                                                    video.revisionHistory.toReversed().slice(0, 1)?.map((revision) => (
                                                                        <p
                                                                            key={revision.timestamp}
                                                                            style={{
                                                                                fontSize: "16px",
                                                                                margin: "0",
                                                                                padding: "0",
                                                                                textAlign: "left",
                                                                            }}
                                                                        >
                                                                            <small style={{
                                                                                fontSize: "11px",
                                                                                color: "grey",
                                                                            }}>
                                                                                {revision.timestamp}
                                                                            </small>
                                                                            <span
                                                                                style={{
                                                                                    color: "blue",
                                                                                    fontSize: "12px",
                                                                                }}
                                                                            >{extractNameFromEmail(revision.username)}</span>{" "} : <pre> {revision.description} </pre>
                                                                        </p>
                                                                    ))
                                                                }

                                                                {video.revisionHistory.length > 1 ? (
                                                                    <SecondaryButton
                                                                        onClick={() => {
                                                                            setOpenCompareModal(video);
                                                                        }}
                                                                        style={
                                                                            {
                                                                                fontSize: '10px',
                                                                                padding: "5px",
                                                                                textAlign: 'center'
                                                                            }
                                                                        }
                                                                    >
                                                                        <span>See More ></span>
                                                                    </SecondaryButton>
                                                                ) : null}

                                                            </Td>
                                                            <Td>{video?.revisionHistory?.length}</Td>
                                                            <VideoTd
                                                                style={{
                                                                    cursor: "pointer",
                                                                    maxWidth: "300px",
                                                                    position: "relative",
                                                                }}
                                                                tip={
                                                                    `${activeButton}`
                                                                }
                                                            >
                                                                {video.notification ? (
                                                                    <ToastCounterCircle
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "2px",
                                                                            right: "30px",
                                                                        }
                                                                        }
                                                                    >
                                                                        <AiOutlineBell size={15}/>
                                                                        {/*{videoNotificationCount[video._id] || 1}*/}
                                                                    </ToastCounterCircle>
                                                                ) : null}

                                                                {isVideo(video.link) ? (
                                                                    <video style={{cursor: "pointer"}} onClick={() => {
                                                                        TogglePreviewImage()
                                                                        setPreviewImageSrc(process.env.REACT_APP_IMAGE_URL + video.c_link)
                                                                    }}>
                                                                        <source
                                                                            src={process.env.REACT_APP_IMAGE_URL + video.c_link}
                                                                            type="video/mp4"/>
                                                                    </video>
                                                                ) : (
                                                                    <img src={
                                                                        process.env.REACT_APP_IMAGE_URL + video.c_link
                                                                    } alt={'img'}
                                                                         style={{cursor: "pointer"}}
                                                                         onClick={() => {
                                                                             TogglePreviewImage()
                                                                             setPreviewImageSrc(process.env.REACT_APP_IMAGE_URL + video.c_link)
                                                                         }}></img>
                                                                )}
                                                            </VideoTd>
                                                            <Td>
                                                                {isVideo(video.link) ? (
                                                                    "Video"
                                                                ) : (
                                                                    "Image"
                                                                )}
                                                            </Td>
                                                            <Td>
                                                                {/*{video.approved.charAt(0).toUpperCase() +*/}
                                                                {/*    video.approved.slice(1)}*/}

                                                                <Button
                                                                    variant='outlined'
                                                                    disabled={userRole === "uploader"}
                                                                    background={userRole === "uploader" ? "grey" : null}
                                                                    onClick={() => {
                                                                        setPreviewVideo(video);
                                                                    }}>
                                                                    {video.approved.charAt(0).toUpperCase() +
                                                                        video.approved.slice(1)}
                                                                </Button>
                                                            </Td>
                                                            {userRole === 'user' ? null : <Td style={{
                                                                position: "relative"
                                                            }}>
                                                                {video.approved === "rejected" ? (
                                                                    userRole === "user" ? null : (
                                                                        <Button
                                                                            variant="soft"
                                                                            startDecorator={<AiOutlineUpload/>}
                                                                            onClick={() => {
                                                                                ToggleRevision()
                                                                                setCreativeId(video);
                                                                            }}>
                                                                            <Tooltip text="Upload Revision">
                                                                                Upload Revision
                                                                            </Tooltip>
                                                                        </Button>
                                                                    )
                                                                ) : null}
                                                                <br/>
                                                                {/*<LogOutButton*/}
                                                                {/*    disabled={userRole === "uploader"}*/}
                                                                {/*    background={userRole === "uploader" ? "grey" : null}*/}
                                                                {/*    onClick={() => {*/}
                                                                {/*        setPreviewVideo(video);*/}
                                                                {/*    }}>*/}
                                                                {/*    Details*/}
                                                                {/*</LogOutButton>*/}
                                                                {video.approved === "approved" ? (
                                                                    (userRole === "publisher" || userRole === 'admin') &&
                                                                    video.flagged === false ? (
                                                                        <>
                                                                            <Button
                                                                                startDecorator={<MdPublish/>}
                                                                                size="sm"
                                                                                variant="outlined"
                                                                                color="neutral"
                                                                                disabled={userRole === "uploader"}
                                                                                background={userRole === "user" ? "grey" : null}
                                                                                onClick={() => {
                                                                                    openPublishModal(video);
                                                                                }}
                                                                            >
                                                                                Publish
                                                                            </Button>
                                                                            {/*<p*/}
                                                                            {/*    style={{*/}
                                                                            {/*        fontSize: '12px',*/}
                                                                            {/*        position: 'absolute',*/}
                                                                            {/*        bottom: '0',*/}
                                                                            {/*        right: '10px',*/}
                                                                            {/*        cursor: 'pointer'*/}
                                                                            {/*    }}*/}
                                                                            {/*    onClick={() => {*/}
                                                                            {/*        setFlag(video._id);*/}
                                                                            {/*    }}*/}
                                                                            {/*>*/}
                                                                            {/*    Flag*/}
                                                                            {/*</p>*/}

                                                                            &nbsp;&nbsp;
                                                                            <Button
                                                                                variant="soft"
                                                                                startDecorator={<BsFlag/>}
                                                                                size="sm"
                                                                                color="danger"
                                                                            >
                                                                                Flag
                                                                            </Button>
                                                                        </>
                                                                    ) : null
                                                                ) : null}

                                                                {video.approved === "published" ? (
                                                                    <SocialMedia>
                                                                        {video.publishLinks[0].instagram ? (
                                                                            <a
                                                                                href={video.publishLinks[0].instagram}
                                                                                target="#"
                                                                            >
                                                                                <IoLogoInstagram/>
                                                                            </a>
                                                                        ) : null}
                                                                        {video.publishLinks[0].facebook ? (
                                                                            <a
                                                                                href={video.publishLinks[0].facebook}
                                                                                target="#"
                                                                            >
                                                                                <IoLogoFacebook/>
                                                                            </a>
                                                                        ) : null}
                                                                        {video.publishLinks[0].twitter ? (
                                                                            <a
                                                                                href={video.publishLinks[0].twitter}
                                                                                target="#"
                                                                            >
                                                                                <IoLogoTwitter/>
                                                                            </a>
                                                                        ) : null}
                                                                        {video.publishLinks[0].youtube ? (
                                                                            <a
                                                                                href={video.publishLinks[0].youtube}
                                                                                target="#"
                                                                            >
                                                                                <IoLogoYoutube/>
                                                                            </a>
                                                                        ) : null}
                                                                        {video.publishLinks[0].linkedin ? (
                                                                            <a
                                                                                href={video.publishLinks[0].linkedin}
                                                                                target="#"
                                                                            >
                                                                                <IoLogoLinkedin/>
                                                                            </a>
                                                                        ) : null}
                                                                    </SocialMedia>
                                                                ) : null}
                                                            </Td>}
                                                            {video.approved === "published" ? (
                                                                <Td style={{
                                                                    position: "relative"
                                                                }}>
                                                                    {userRole === "user" || userRole === 'reviewer' ||
                                                                    video.flagged ? null : (
                                                                        <p
                                                                            style={{
                                                                                fontSize: '12px',
                                                                                position: 'absolute',
                                                                                bottom: '0',
                                                                                right: '10px',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={() => {
                                                                                setFlag(video._id);
                                                                            }}
                                                                        >
                                                                            Flag
                                                                        </p>
                                                                    )}
                                                                </Td>
                                                            ) : null}
                                                        </Tr>
                                                    ))}
                                                </>
                                            ))}
                                        </Tbody>
                                    )}
                            </Table>
                        )}
                        <Toaster
                            position="bottom-right"
                        />
                    </BodyWrapper>

                    {
                        flag && (
                            <PublishModal>
                                <PublishModalInnerwrapper>
                                    <CloseIcon>
                                        <IoIosCloseCircleOutline onClick={e => setFlag(null)}/>
                                    </CloseIcon>
                                    <PublishModalBody>
                                        <h1>Are You Sure</h1>
                                        <p>Are you sure you want to flag this video?</p>
                                        <Buttoni
                                            onClick={() => {
                                                submitFlag(flag);
                                            }}
                                        >
                                            Flag
                                        </Buttoni>
                                    </PublishModalBody>
                                </PublishModalInnerwrapper>
                            </PublishModal>
                        )
                    }


                    {
                        previewImage && (
                            <PublishModal>
                                <ChatImagePreviewWrapper style={{
                                    background: "transparent",
                                    width: "60%",
                                    borderColor: "red"
                                }}
                                >
                                    <CloseIcon
                                        onClick={TogglePreviewImage}>
                                        <IoIosCloseCircleOutline style={{
                                            color: "white"
                                        }}/>
                                    </CloseIcon>
                                    <ImagePreview style={{
                                        border: 'none',
                                        width: '850px',
                                        height: '850px'
                                    }}>
                                        {isVideo(previewImageSrc) ? (
                                            <video controls>
                                                <source src={previewImageSrc} type="video/mp4"/>
                                            </video>
                                        ) : (
                                            <img src={previewImageSrc} alt={'img'} onClick={TogglePreviewImage}></img>
                                        )}
                                    </ImagePreview>
                                </ChatImagePreviewWrapper>
                            </PublishModal>
                        )
                    }
                    {
                        previewVideo && (
                            <PublishModal>
                                <ChatImagePreviewWrapper>
                                    <CloseIcon
                                        onClick={() => {
                                            setPreviewVideo(false)
                                        }}
                                    >
                                        <IoIosCloseCircleOutline/>
                                    </CloseIcon>
                                    <LeftSide>
                                        <FloatingSideMenu>
                                            <FloatingSideMenuContent tip={"Copy Link"}
                                                                     onClick={
                                                                         async () => {
                                                                             const link = `${window.location}&video=${previewVideo._id}`;
                                                                             await navigator.clipboard.writeText(link);
                                                                             toast.success("Link Copied");
                                                                         }
                                                                     }
                                            >
                                                <IoMdLink/>
                                            </FloatingSideMenuContent>
                                            <FloatingSideMenuContent tip={"Download"}
                                                                     onClick={
                                                                         () => {
                                                                             window.open(previewVideo.link, '_blank')
                                                                         }
                                                                     }
                                            >
                                                <AiOutlineCloudDownload/>
                                            </FloatingSideMenuContent>
                                            {
                                                userRole === "admin" || userRole === 'publisher' ? (
                                                    <>
                                                        {
                                                            previewVideo?.approved === "published" ? (
                                                                <FloatingSideMenuContent
                                                                    tip={"Edit Publish"}
                                                                    onClick={() => {
                                                                        setPublishModal(true);
                                                                        setSelectedVideoId(previewVideo);
                                                                        setSocialMedia(
                                                                            {
                                                                                facebook: previewVideo.publishLinks[0].facebook,
                                                                                instagram: previewVideo.publishLinks[0].instagram,
                                                                                twitter: previewVideo.publishLinks[0].twitter,
                                                                                youtube: previewVideo.publishLinks[0].youtube,
                                                                                linkedin: previewVideo.publishLinks[0].linkedin,
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    <MdEditCalendar/>
                                                                </FloatingSideMenuContent>
                                                            ) : null
                                                        }
                                                    </>
                                                ) : null}
                                            {
                                                !(previewVideo.approved === "published") & userRole === "admin" | userRole === 'creator' ? (
                                                    <FloatingSideMenuContent tip={
                                                        "Edit Title"
                                                    } onClick={() => {
                                                        Toggle()
                                                    }}>
                                                        <AiOutlineEdit/>
                                                    </FloatingSideMenuContent>
                                                ) : null
                                            }
                                            {
                                                !(previewVideo.approved === "published") & userRole === "admin" ? (
                                                    <FloatingSideMenuContent tip={
                                                        "Delete"
                                                    } onClick={() => {
                                                        setShowDeleteCreativeConfirmModal(true);
                                                    }}>
                                                        <RiDeleteBin6Line/>
                                                    </FloatingSideMenuContent>
                                                ) : null}


                                            {/*<FloatingSideMenuContent tip={"AI Content"}>*/}
                                            {/*    <RiAiGenerate/>*/}
                                            {/*</FloatingSideMenuContent>*/}
                                        </FloatingSideMenu>

                                        <h3> {previewVideo.title}</h3>
                                        <VideoImageWrapper>
                                            {isVideo(previewVideo.link) ? (
                                                <video controls>
                                                    <source src={previewVideo.link} type="video/mp4"/>
                                                    <source src={previewVideo.link} type="video/webm"/>
                                                    <source src={previewVideo.link} type="video/ogg"/>
                                                </video>
                                            ) : (
                                                <img src={previewVideo.link} alt={"video"}></img>
                                            )}
                                            {previewVideo.revisionHistory.length >= 1 ? (
                                                <Buttoni
                                                    style={{
                                                        position: "absolute",
                                                        top: "5px",
                                                        right: "5px",
                                                        background: "transparent",
                                                        backgroundColor: "black",
                                                    }}
                                                    onClick={() => {
                                                        setOpenCompareModal(previewVideo);
                                                    }}
                                                >
                                                    <MdCompare/>
                                                </Buttoni>
                                            ) : null}
                                        </VideoImageWrapper>
                                        <CaptionBox onClick={
                                            () => {// copy to clipboard if caption is there else do noting
                                                if (previewVideo.caption) {
                                                    navigator.clipboard.writeText(previewVideo.caption);
                                                    toast.success("Caption Copied");
                                                }
                                            }
                                        }>
                                            <CaptionContainerBox>

                                                {
                                                    !previewVideo.caption ? (
                                                        <Button startDecorator={<RiAiGenerate/>}
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                }}
                                                                onClick={
                                                                    () => {
                                                                        ToggleAiInputModal()
                                                                    }
                                                                }
                                                                variant="outlined"
                                                                color="danger"
                                                        >
                                                            AI Caption
                                                        </Button>
                                                    ) : null
                                                }

                                                {
                                                    captionLoading ? (
                                                            <span>Ai Generating...</span>
                                                        ) :
                                                        <pre>{previewVideo?.caption}</pre>
                                                }

                                                {
                                                    previewVideo.caption && (
                                                        <Chip
                                                            color="warning"
                                                            startDecorator={<AiOutlineCopy/>}
                                                            style={{
                                                                position: 'absolute',
                                                                top: '10px',
                                                                right: '10px',
                                                            }}>
                                                            Copy
                                                        </Chip>)
                                                }`
                                            </CaptionContainerBox>
                                            {
                                                (previewVideo.approved) !== 'published' && (userRole === "creator" || userRole === "admin") ? (
                                                        <>
                                                            <CaptionAiButtonContainer>
                                                                <Button size='sm' variant="solid" color="warning" onClick={
                                                                    () => {
                                                                        ToggleAiInputModal()
                                                                    }
                                                                }
                                                                        startDecorator={<PiMagicWandThin/>}
                                                                >
                                                                    Tune Caption
                                                                </Button>
                                                                <Button
                                                                    size='sm'
                                                                    variant="outlined" color="warning"
                                                                    onClick={
                                                                        () => {
                                                                            rewriteCaption(previewVideo._id, 'shorten')
                                                                        }
                                                                    }>
                                                                    Shorten
                                                                </Button>
                                                                <Button
                                                                    size='sm'
                                                                    variant="outlined" color="warning"
                                                                    onClick={
                                                                        () => {
                                                                            rewriteCaption(previewVideo._id, 'expand')
                                                                        }
                                                                    }
                                                                >
                                                                    Expand
                                                                </Button>
                                                                <Button
                                                                    size='sm'
                                                                    variant="outlined" color="warning"
                                                                    onClick={
                                                                        () => {
                                                                            rewriteCaption(previewVideo._id, 'simplify')
                                                                        }
                                                                    }
                                                                >
                                                                    More Casual
                                                                </Button>
                                                                <Button
                                                                    size='sm'
                                                                    variant="outlined" color="warning"
                                                                    onClick={
                                                                        () => {
                                                                            rewriteCaption(previewVideo._id, 'formalize')
                                                                        }
                                                                    }>
                                                                    More Formal
                                                                </Button>
                                                            </CaptionAiButtonContainer>
                                                        </>
                                                    )
                                                    : null
                                            }
                                        </CaptionBox>
                                        {previewVideo.approved === "pending" ? (
                                            userRole === "admin" || userRole === 'reviewer' ? (
                                                <ButtonWrapper>
                                                    <Button size='md' color="success" value="approved" onClick={e => {
                                                        setApproveAlert(previewVideo)
                                                    }}>Approve
                                                    </Button>
                                                    &nbsp;&nbsp;
                                                    <Button color="danger" size='md' onClick={e => {
                                                        setSelectedVideo(previewVideo)
                                                        setRejectAlert(true)
                                                    }}>Reject
                                                    </Button>
                                                </ButtonWrapper>
                                            ) : null
                                        ) : null}

                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'start',
                                            flexDirection: 'column',
                                            marginTop: '10px',
                                            backgroundColor: '#f5f5f5',
                                            width: "99%",
                                            borderRadius: '10px',
                                            padding: '5px',
                                        }}>
                                            {
                                                previewVideo?.uploader?.email ? (
                                                    <div style={{
                                                        fontSize: '12px',
                                                        padding: '5px',
                                                    }}>
                                                        <span>Content Uploaded by </span>
                                                        <span>{
                                                            previewVideo?.uploader?.firstName ? (
                                                                previewVideo?.uploader?.firstName + " " + previewVideo?.uploader?.lastName
                                                            ) : (
                                                                extractNameFromEmail(previewVideo?.uploader?.email)
                                                            )
                                                        }</span>
                                                        <span> on {formatDate(previewVideo.createTime)}</span>
                                                        <span> through {previewVideo.upload_platform === 'WHATSAPP' ? (
                                                            <span>Whatsapp</span>
                                                        ) : (
                                                            <span>Web</span>
                                                        )}</span>
                                                    </div>
                                                ) : null
                                            }
                                            {
                                                previewVideo?.lastUpdatedBy?.email ? (<div style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        flexDirection: "row",
                                                        justifyContent: "space-between"
                                                    }}>
                                                        <div style={{
                                                            fontSize: '12px',
                                                            textAlign: 'right',
                                                            padding: '5px',
                                                        }}>
                                                            <span>Last Updated by </span>
                                                            <span>{extractNameFromEmail(previewVideo.lastUpdatedBy.email)} </span>
                                                            {
                                                                previewVideo?.lastUpdateTime ? (
                                                                    <span>on {formatDate(previewVideo.lastUpdateTime)}</span>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            {!viewChatBox &&
                                                // <p style={{
                                                //     margin: '0',
                                                //     padding: '0',
                                                //     width: '100%',
                                                //     display: 'flex',
                                                //     alignItems: 'center',
                                                //     justifyContent: 'end',
                                                //     cursor: 'pointer'
                                                // }}> Chat Box &nbsp;&nbsp;
                                                //     <IoChatbubbleEllipsesOutline size={30}
                                                //                                  onClick={() => setViewChatBox(!viewChatBox)}/>
                                                // </p>
                                                <Button
                                                    sx={{
                                                        position: 'absolute',
                                                        bottom: '1vh',
                                                        right: '25px',
                                                    }}
                                                    size='sm' variant="outlined" color="primary" onClick={
                                                    () => {
                                                        setViewChatBox(!viewChatBox)
                                                    }
                                                }
                                                    startDecorator={<IoChatbubbleEllipsesOutline/>}
                                                >
                                                    Chat Box
                                                </Button>
                                            }
                                        </div>
                                    </LeftSide>

                                    {viewChatBox &&
                                        <RightSide>
                                            <Buttoni onClick={() => setViewChatBox(!viewChatBox)} style={{
                                                position: 'absolute',
                                                top: '1vh',
                                                left: '25px',
                                                background: '#3e3e49',
                                                color: '#fff',
                                                borderRadius: '10px',
                                                padding: '5px',
                                                cursor: 'pointer',
                                                fontSize: '25px',
                                                fontWeight: 'bold',
                                                zIndex: '1',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}><Tooltip text="Close ChatBox">
                                                <BiSolidChevronRight/>
                                            </Tooltip></Buttoni>
                                            <Chat videoId={previewVideo._id} projectId={previewVideo.project} socket={socket}/>
                                        </RightSide>
                                    }
                                </ChatImagePreviewWrapper>
                            </PublishModal>
                        )
                    }
                    {
                        publishModal && (
                            <PublishModal>
                                <PublishModalInnerwrapper>
                                    <PublishModalBody>
                                        <h1>Social Media Links</h1>
                                        <input
                                            type="text"
                                            placeholder="Facebook Link"
                                            value={socialMedia?.facebook ? socialMedia?.facebook : null}
                                            onChange={(e) => {
                                                setSocialMedia({
                                                    ...socialMedia,
                                                    facebook: e.target.value,
                                                });
                                            }}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Instagram Link"
                                            value={socialMedia?.instagram ? socialMedia?.instagram : null}
                                            onChange={(e) => {
                                                setSocialMedia({
                                                    ...socialMedia,
                                                    instagram: e.target.value,
                                                });
                                            }}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Twitter Link"
                                            value={socialMedia?.twitter ? socialMedia?.twitter : null}
                                            onChange={(e) => {
                                                setSocialMedia({
                                                    ...socialMedia,
                                                    twitter: e.target.value,
                                                });
                                            }}
                                        />
                                        <input
                                            type="text"
                                            placeholder="LinkedIn Link"
                                            value={socialMedia?.linkedin ? socialMedia?.linkedin : null}
                                            onChange={(e) => {
                                                setSocialMedia({
                                                    ...socialMedia,
                                                    linkedin: e.target.value,
                                                });
                                            }}
                                        />
                                        <input
                                            type="text"
                                            placeholder="Youtube Link"
                                            value={socialMedia?.youtube ? socialMedia?.youtube : null}
                                            onChange={(e) => {
                                                setSocialMedia({
                                                    ...socialMedia,
                                                    youtube: e.target.value,
                                                });
                                            }}
                                        />
                                        <Buttoni
                                            disabled={userRole === "uploader"}
                                            background={userRole === "user" ? "grey" : null}
                                            onClick={() => {
                                                publishVideo(selectedVideoId);
                                                setPublishModal(false);
                                            }}
                                        >
                                            Publish
                                        </Buttoni>
                                    </PublishModalBody>
                                    <span
                                        onClick={() => {
                                            setPublishModal(false);
                                        }}
                                    ><IoIosCloseCircleOutline/></span>
                                </PublishModalInnerwrapper>
                            </PublishModal>
                        )
                    }

                    {
                        openCompareModal && (
                            <PublishModal>
                                <ChatImagePreviewWrapper>
                                    <CloseIcon
                                        onClick={() => {
                                            setOpenCompareModal(null);
                                        }}
                                    >
                                        <IoIosCloseCircleOutline/>
                                    </CloseIcon>
                                    <CompareImage>
                                        <ComparedCard>
                                            <h4>Current Version</h4>
                                            <CompareCardImageWrapper>
                                                {isVideo(openCompareModal.link) ? (
                                                    <video controls>
                                                        <source src={openCompareModal.link} type="video/mp4"/>
                                                    </video>
                                                ) : (
                                                    <img src={openCompareModal.link} alt={"Link"}></img>
                                                )}
                                            </CompareCardImageWrapper>
                                            <span><p>{formatDate(openCompareModal?.createTime)}</p></span>
                                            <CompareCardDescription>
                                                    <pre
                                                        style={{
                                                            whiteSpace: "pre-wrap",
                                                            wordWrap: "break-word",
                                                        }}
                                                    ></pre>
                                            </CompareCardDescription>
                                        </ComparedCard>
                                        {openCompareModal.revisionHistory.reverse().map((item, index, array) => {
                                            const reverseIndex = array.length - index;
                                            return (
                                                <ComparedCard key={index}>
                                                    <h4>Previous Version {reverseIndex}</h4>
                                                    <CompareCardImageWrapper>
                                                        {isVideo(item.link) ? (
                                                            <video controls>
                                                                <source src={item.link} type="video/mp4"/>
                                                            </video>
                                                        ) : (
                                                            <img src={item.link} alt={"link"}></img>
                                                        )}
                                                    </CompareCardImageWrapper>
                                                    <span><p>{item.timestamp}</p></span>
                                                    <CompareCardDescription>
                                                        <p>Feedback</p>
                                                        <pre
                                                            style={{
                                                                whiteSpace: "pre-wrap",
                                                                wordWrap: "break-word",
                                                            }}
                                                        >{item.description}</pre>
                                                    </CompareCardDescription>
                                                </ComparedCard>
                                            );
                                        })}
                                    </CompareImage>
                                </ChatImagePreviewWrapper>
                            </PublishModal>
                        )
                    }
                    {/*<FooterWrapper>*/
                    }
                    {/*    <div*/
                    }
                    {/*        style={{*/
                    }
                    {/*            display: "flex",*/
                    }
                    {/*            alignItems: "center",*/
                    }
                    {/*        }}*/
                    }
                    {/*    >*/
                    }
                    {/*        Powered by <img src="/noisiv.webp" alt="logo"/>*/
                    }
                    {/*        Noisiv Consulting*/
                    }
                    {/*    </div>*/
                    }
                    {/*</FooterWrapper>*/
                    }
                </Wrapper>
                <AiContentForm show={AiInputModal} close={ToggleAiInputModal} creativeId={previewVideo?._id}
                               onClose={(data) => {
                                   if (data.result) {
                                       setPreviewVideo({
                                           ...previewVideo,
                                           caption: data.result
                                       })
                                   }
                               }}/>
                <CreateCreative close={Toggle} show={modalView} project={currentProject} content={previewVideo}
                                onSubmit={(data) => {
                                    setPreviewVideo({
                                        ...previewVideo,
                                        caption: data.caption,
                                        title: data.title,
                                    })
                                }}/>
                <CreativeRevision show={revisionModal} close={ToggleRevision} creativeID={creativeId}
                                  project={currentProject}/>
                <SlideBar role={userRole} project={currentProject} open={showUserManageModal} close={setShowUserManageModal}/>
                {
                    approveAlert && (
                        <AlertModal
                            title="ARE YOU SURE YOU WANT TO APPROVE ?"
                            subtitle="Once approved, any further changes in this creative will attract revision charges."
                            onConfirm={async () => {
                                updateCreativeType(
                                    {
                                        target: {
                                            value: "approved"
                                        }
                                    },
                                    approveAlert
                                )
                            }}
                            onCancel={
                                () => {
                                    setApproveAlert(false);
                                }
                            }
                        />
                    )
                }
                {
                    rejectAlert ? <ModalContainer>
                        <Modal>
                            <ModalHeader>
                                <h3>Reject Content</h3>
                                <CloseButton
                                    onClick={() => setRejectAlert(false)}><IoIosCloseCircleOutline/></CloseButton>
                            </ModalHeader>
                            <ModalBody onKeyDown={handleKeyDown}>
                                <InputWrapper>
                                    <TextInput
                                        placeholder="Reason for rejection"
                                        value={selectedVideo.description}
                                        onChange={(e) => {
                                            setSelectedVideo({
                                                ...selectedVideo,
                                                description: e.target.value,
                                            });
                                        }}/>
                                </InputWrapper>
                            </ModalBody>
                            <ModalFooter>
                                <Buttoni value="rejected" onClick={e => {
                                    updateCreativeType(e, selectedVideo)
                                }}>Reject</Buttoni>
                            </ModalFooter>
                        </Modal>

                    </ModalContainer> : null
                }


                {
                    showDeleteCreativeConfirmModal && (
                        <AlertModal
                            title="Delete Content"
                            subtitle="Do you really want to delete the content?"
                            onConfirm={async () => {
                                await removeCreativeFromProject(previewVideo._id);
                                setPreviewVideo(false);
                                setRejectAlert(false);
                                setApproveAlert(false);
                                setShowDeleteCreativeConfirmModal(false);
                            }}
                            onCancel={
                                () => {
                                    setShowDeleteCreativeConfirmModal(false);
                                }
                            }
                        />
                    )
                }
            </>
        )
            ;
    }
;
export default Board;
