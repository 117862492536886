import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalDialog,
  DialogTitle,
  Divider,
  Stack,
  FormControl,
  Select,
  Option,
  Button,
} from "@mui/joy";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import { useCallback } from "react";
import toast from "react-hot-toast";

const EditMemberRoleModal = ({
  show,
  close,
  memberData,
  currentRole, // Removed unused 'options' prop
  organisationId,
}) => {
  const [newRole, setNewRole] = useState(memberData.role || "");

  // Reset role when memberData changes
  useEffect(() => {
    console.log("memberData", memberData);
    setNewRole(memberData.role);
  }, [memberData]);

  const handleRoleChange = (event, value) => {
    setNewRole(value);
  };

  const editMemberRole = async (userId) => {
    try {
      // Fixed API endpoint URL (removed undefined params.id)
      await axios.put(
        `${process.env.REACT_APP_API_URL}organisation/user/update-role/${organisationId}`,
        {
          userId: userId,
          role: newRole,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      close({ ...memberData, role: newRole }); // Pass updated role back
      toast.success("Role updated successfully");
    } catch (error) {
      toast.error("Failed to update role");
      console.error("Update error:", error);
    }
  };

  const getRoles = useCallback(() => {
    return currentRole === "moderator"
      ? [
          {
            value: "member",
            label: "User",
            explanation:
              "Can access core features without administrative privileges.",
          },
          {
            value: "moderator",
            label: "Moderator",
            explanation: "Can manage users but not organization settings.",
          },
        ]
      : [
          {
            value: "member",
            label: "User",
            explanation:
              "Can access core features without administrative privileges.",
          },
          {
            value: "moderator",
            label: "Moderator",
            explanation: "Can add/remove users and manage content.",
          },
          {
            value: "owner",
            label: "Admin",
            explanation: "Full control over organization settings and users.",
          },
        ];
  }, [currentRole]);

  return (
    <Modal open={show} onClose={close}>
      <ModalDialog sx={{ maxWidth: "40%" }}>
        <DialogTitle>
          Edit Role for {memberData?.firstName + " " + memberData?.lastName}
          <button
            onClick={close}
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              border: "none",
              background: "transparent",
              cursor: "pointer",
            }}
          >
            <IoIosCloseCircleOutline size={24} />
          </button>
        </DialogTitle>
        <Divider sx={{ marginY: 2 }} />
        <Stack spacing={2}>
          <p>Email: {memberData.email}</p>

          <FormControl fullWidth>
            <Select
              value={newRole}
              onChange={handleRoleChange}
              placeholder="Select Role"
            >
              {getRoles().map(
                (
                  option, // Use dynamically generated roles
                ) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ),
              )}
            </Select>
          </FormControl>

          <Divider sx={{ marginY: 2 }} />
          <Button
            onClick={() => editMemberRole(memberData._id)} // Only pass user ID
            sx={{ width: "100%" }}
          >
            Update
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};

export default EditMemberRoleModal;
