import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import AddMember, {
  roles,
} from "../ProjectMemberOperationComponents/addMemberModal/addMember";
import { toast } from "react-hot-toast";
import UserInfoCard from "../../../Components/user-info-card/user-info-card";
import { Box, Button, Drawer } from "@mui/joy";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import { AiOutlineUserAdd } from "react-icons/ai";
import EditMemberRoleModalProject from "../ProjectMemberOperationComponents/EditMemberModalProject";
import RemoveMemberModalProject from "../ProjectMemberOperationComponents/ RemoveMemberModal";
import AddMemberProject from "../ProjectMemberOperationComponents/addMemberModal/addMember";

const SlideBar = ({ role, project, open, close }) => {
  const [projectMembers, setProjectMembers] = useState([]);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [editRoleModal, setEditRoleModal] = useState(false);
  const [memberData, setMemberData] = useState("");
  const menuRef = useRef(null);
  const addMemberToggle = () => setAddMemberModal(!addMemberModal);
  const params = useParams();
  const toggleMenu = () => {
    close(!open);
  };
  const [activeCard, setActiveCard] = useState(null);

  const fetchProjectMembers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}project/users/${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      );
      setProjectMembers(response.data);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    fetchProjectMembers();
  }, [open, addMemberModal]);

  return (
    <>
      <Drawer
        open={open}
        onClose={toggleMenu}
        size="sm"
        anchor="right"
        ref={menuRef}
      >
        {/* Menu Content Container */}
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              height: "5%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography level="title-md">Project Members</Typography>
            <Divider />
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflowY: "auto",
              overflowX: "hidden",
              p: 2,
            }}
          >
            {projectMembers.map((item) => (
              <UserInfoCard
                key={item._id}
                user={item}
                cardRole={item.role === "admin" ? "Product Owner" : item.role}
                canRemove={role === "admin" && item.role !== "admin"}
                onRemove={() => {
                  setAlertModal(true);
                  setMemberData(item);
                }}
                onEdit={() => {
                  setMemberData(item);
                  setEditRoleModal(true);
                }}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
            ))}
          </Box>

          {/* Footer: Add User Button */}
          {role === "admin" && (
            <Box
              sx={{
                height: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                size="sm"
                startDecorator={<AiOutlineUserAdd />}
                variant="outlined"
                color="warning"
                onClick={addMemberToggle}
              >
                Add Project Member
              </Button>
            </Box>
          )}
        </Box>
      </Drawer>
      <AddMemberProject
        show={addMemberModal}
        close={addMemberToggle}
        project={project}
      />
      <RemoveMemberModalProject
        open={alertModal}
        onClose={() => setAlertModal(false)}
        memberData={memberData}
        projectId={params.id} // Pass project ID for API call
        refreshMembers={fetchProjectMembers} // Refresh member list after removal
      />
      ;
      <EditMemberRoleModalProject
        open={editRoleModal}
        onClose={() => setEditRoleModal(false)}
        memberData={memberData}
        roles={roles}
        projectId={params.id} // Pass project ID for API call
        refreshMembers={fetchProjectMembers} // Refresh member list after update
      />
      ;
    </>
  );
};
export default SlideBar;
