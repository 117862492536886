import React, { useState } from "react";
import {
    Button,
    Typography,
    Select,
    Option,
    Modal,
    ModalDialog,
    ModalClose,
    FormControl,
    FormLabel,
    Input,
    Stack,
    IconButton,
} from "@mui/joy";
import { IoIosCloseCircleOutline } from "react-icons/io";

const EmailRoleForm = ({ roles, onSubmit }) => {
    const [inputs, setInputs] = useState([{ email: "", role: "" }]);

    const handleInputChange = (index, field, value) => {
        const newInputs = [...inputs];
        newInputs[index][field] = value;
        setInputs(newInputs);
    };

    const handleAddRow = () => {
        setInputs([...inputs, { email: "", role: "" }]);
    };

    const handleRemoveRow = (index) => {
        if (inputs.length > 1) {
            setInputs(inputs.filter((_, i) => i !== index));
        }
    };

    const handleSubmit = () => {
        onSubmit(inputs);
    };

    return (
        <Modal open={true}>
            <ModalDialog>
                <Typography level="h4">Add Members</Typography>
                <ModalClose>
                    <IoIosCloseCircleOutline />
                </ModalClose>
                <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>You</FormLabel>
                        <Input value="Admin" disabled />
                    </FormControl>

                    {inputs.map((input, index) => (
                        <Stack
                            key={index}
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <FormControl sx={{ flex: 2 }}>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    placeholder="Enter email"
                                    name="email"
                                    value={input.email}
                                    onChange={(event) =>
                                        handleInputChange(
                                            index,
                                            "email",
                                            event.target.value,
                                        )
                                    }
                                />
                            </FormControl>

                            <FormControl sx={{ flex: 1 }}>
                                <FormLabel>Role</FormLabel>
                                <Select
                                    value={input.role || ""} // Ensuring that if role is empty, it defaults to an empty string
                                    onChange={(_, newValue) =>
                                        handleInputChange(
                                            index,
                                            "role",
                                            newValue,
                                        )
                                    }
                                >
                                    <Option value="" disabled>
                                        Select Role
                                    </Option>{" "}
                                    {/* Default placeholder option */}
                                    {roles.map((role) => (
                                        <Option
                                            key={role.value}
                                            value={role.value}
                                        >
                                            {role.label}
                                        </Option>
                                    ))}
                                </Select>
                            </FormControl>

                            {inputs.length > 1 && (
                                <IconButton
                                    color="danger"
                                    onClick={() => handleRemoveRow(index)}
                                >
                                    <IoIosCloseCircleOutline />
                                </IconButton>
                            )}
                        </Stack>
                    ))}

                    <Stack direction="row" spacing={2}>
                        <Button onClick={handleAddRow}>Add Member</Button>
                        <Button onClick={handleSubmit}>Submit</Button>
                    </Stack>
                </Stack>
            </ModalDialog>
        </Modal>
    );
};

export default EmailRoleForm;
