import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useParams } from "react-router-dom";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalDialog,
    ModalClose,
    Typography,
    Select,
    Option,
} from "@mui/joy";
import industriesObjects from "../../../constants/industries";
import Toggle from "../../../Components/Toggle/toggle";
import { containsOnlySpaces } from "../../../Components/Helpers/helper";

const CreateProject = ({ show, close, organisation, project }) => {
    const [name, setName] = useState("");
    const [industryType, setIndustryType] = useState("");
    const [visibility, setVisibility] = useState("");
    const [loader, setLoader] = useState(false);
    const [isActive, setIsActive] = useState(true);

    const params = useParams();

    useEffect(() => {
        if (project) {
            setName(project.name);
            setIndustryType(project.description);
            setVisibility(project.visibility);
            setIsActive(project.isActive);
        } else {
            setName("");
            setIndustryType("");
            setVisibility("");
            setIsActive(true);
        }
    }, [show, project]);

    const validateForm = () => {
        if (name === "" || containsOnlySpaces(name)) {
            toast.error("Project name is required");
            return false;
        } else if (industryType === "") {
            toast.error("Industry is required");
            return false;
        } else if (visibility === "") {
            toast.error("Visibility is required");
            return false;
        }
        return true;
    };

    const createOrUpdateProject = async () => {
        if (!validateForm()) {
            setLoader(false);
            return;
        }

        setLoader(true);
        const projectData = {
            name,
            description: industryType,
            visibility,
            isActive,
        };

        try {
            if (project) {
                await axios.put(
                    `${process.env.REACT_APP_API_URL}project/${project._id}`,
                    projectData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    },
                );
                toast.success("Project Updated Successfully");
            } else {
                await axios.post(
                    `${process.env.REACT_APP_API_URL}project/${params.id}`,
                    projectData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    },
                );
                toast.success("Project Created Successfully");
            }
            close();
        } catch (e) {
            toast.error("Something went wrong");
        } finally {
            setLoader(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            createOrUpdateProject();
        }
    };

    return (
        <>
            {show && (
                <Modal open={show} onClose={close}>
                    <ModalDialog>
                        <Typography level="title-lg">
                            {!project
                                ? `Create new Project for ${organisation?.name}`
                                : `Edit Project: ${project?.name}`}
                        </Typography>
                        <ModalClose onClick={close}>
                            <IoIosCloseCircleOutline />
                        </ModalClose>

                        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                            <FormLabel>Project Name</FormLabel>
                            <Input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter Project Name"
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                            <FormLabel>Project Category</FormLabel>
                            <Select
                                value={industryType}
                                onChange={(e, newValue) =>
                                    setIndustryType(newValue)
                                }
                                placeholder="Select Industry"
                            >
                                {industriesObjects.map((industry) => (
                                    <Option
                                        key={industry.value}
                                        value={industry.value}
                                    >
                                        {industry.label}
                                    </Option>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                            <FormLabel>Visibility</FormLabel>
                            <Select
                                value={visibility}
                                onChange={(e, newValue) =>
                                    setVisibility(newValue)
                                }
                                placeholder="Select Visibility"
                            >
                                <Option value="public">Public</Option>
                                <Option value="private">Private</Option>
                            </Select>
                        </FormControl>

                        <div
                            style={{
                                margin: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Toggle
                                value={isActive}
                                change={(val) => setIsActive(val)}
                            >
                                {isActive ? "Active" : "Inactive"}
                            </Toggle>
                        </div>

                        <Button
                            color="primary"
                            onClick={createOrUpdateProject}
                            loading={loader}
                            variant="solid"
                        >
                            {!project ? "Create Project" : "Update Project"}
                        </Button>
                    </ModalDialog>
                </Modal>
            )}
        </>
    );
};

export default CreateProject;
